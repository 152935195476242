<!--
 * @Author: lbh
 * @Date: 2022-12-27 14:26:03
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 17:03:33
 * @Description: file content
-->
<template>
  <div class="edit-gsPOSGraphicText1-box">
    <div class="cell-b">
      <h3>主項配置</h3>
      <selfCell
        class="cell-b"
        title="標題"
      >
        <div>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
            type="textarea"
            rows="3"
          />
        </div>
      </selfCell>
      <selfCell
        class="cell-b"
        title="副標題"
      >
        <div>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs[`${lItem.code=='HK'?'':lItem.code}subTitle`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue(`${lItem.code=='HK'?'':lItem.code}subTitle`)"
            type="textarea"
            rows="3"
          />
        </div>
      </selfCell>
      <selfCell title="底圖上傳">
        <selfUpload
          v-model="configs.bgImage"
          type="default"
          style="width:400px"
          @change="setValue('bgImage')"
        />
      </selfCell>
      <selfCell title="圖片上傳">
        <selfUpload
          v-model="configs.image"
          type="default"
          style="width:400px"
          @change="setValue('image')"
        />

      </selfCell>
      <selfCell title="圖片描述">
        <el-input
          v-model="configs.alt"
          type="textarea"
          @change="setValue('alt')"
          placeholder="請輸入圖片描述"
        />
      </selfCell>
    </div>
    <div class="cell-b">
      <h3>子項內容</h3>
      <div
        v-for="(item,index) in configs.items"
        :key="index"
        class="child-cell cell-b"
      >
        <div class="cell">
          <selfUpload
            v-model="item.icon"
            type="default"
            style="width:40px"
            @change="setValue('items')"
          />
          <!-- 操作 -->
          <el-button-group class="btn-group">
            <el-button
              type="primary"
              icon="el-icon-upload2"
              :disabled="index == 0"
              size="mini"
              @click="itemCheck(index,index-1)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-download"
              :disabled="index == configs.items.length-1"
              size="mini"
              @click="itemCheck(index,index+1)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              :disabled="configs.items.length == 1"
              @click="itemDel(index)"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-plus"
              size="mini"
              @click="itemAdd(index)"
            ></el-button>
          </el-button-group>
        </div>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue('items')"
          type="textarea"
          rows="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          subTitle: '',
          image: '',
          bgImage: '',
          paddingTB: 200,
          paddingLR: 250,
          items: [],
        };
      },
    },
    index: {
      default () {
        return '';
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  }
}
</script>

<style lang="less" scoped>
.edit-gsPOSGraphicText1-box {
  .child-cell {
    .cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>